body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Dark red a66960 */
/* Light red e4b8a5 */

@font-face {
  font-family: 'Celliad';
  src: url(/static/media/Celliad.d00a7aed.woff2) format('woff2'),
      url(/static/media/Celliad.86dd73e7.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: #e4b8a5;
}

main {
  -webkit-align-content: center;
          align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  -webkit-hyphens: auto;
          hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
h1 {
  font-size: 8rem;
  color: #a66960;
  font-family: 'Celliad';
  font-weight: normal;
  font-style: normal;
  margin-bottom: 0;
}
h2 {
  margin-top: 0;
  font-family: 'Josefin Sans', sans-serif;
  color: #fff;
}
p {
  font-size: 16px;
}
a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '`';
}

